<template>
  <div>
    <!--Breadcrumb button-->
    <breadcrumb-button>
      <template #items>
        <b-dropdown-item @click="openColumnsModal">
          <feather-icon
              icon="CheckSquareIcon"
              size="16"
          />
          <span class="align-middle ml-50">{{ $t('columns.self') }}</span>
        </b-dropdown-item>
      </template>

    </breadcrumb-button>
    <!--Filters-->
    <filter-card  v-model="filter" v-bind:fields="filterFields" search/>
    <!--Table Card-->
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>{{ $t('menu.closedTrades') }}</h5>
      </b-card-header>
      <b-card-body>
        <closed-trade-table ref="closedTradeTable" v-bind:filters="filter" v-bind:columns="columns" :has-selection="false"
                        @selected="onClosedTradeSelected" :lazy="true" closed-only/>
      </b-card-body>
    </b-card>
    <!--Modal-->
    <columns-modal ref="columns-modal" column-key="tradesClosed" v-model="columns"
                   v-bind:columns-names="columnsNames" v-bind:columns-default="columnsDefaultNames"
    />
    <trade-order-modal ref="trade-order-modal" @close="refreshTables"/>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import BreadcrumbButton from '@/components/widget/breadcrumbButton';
import ClosedTradeSearchRequest from '@/model/trade/TradeSearchRequest';
import ColumnsModal from '@/components/modal/columnsModal';
import { getDefaultCloseTradeFilter, getTradeFields } from '@/model/trade/TradeColumns'
import mixinPermissions from '@/components/mixin/mixinPermissions';
import mixinBase from '@/components/mixin/mixinBase';
import FilterCard from '@/components/widget/FilterCard';
import ClosedTradeFilterFields from '@/model/filter/TradeFilterFields';
import ClosedTradeTable from '@/components/tables/ClosedTradeTable';
import ClosedTradeModal from '@/components/modal/closedTradeModal';
import TradeOrderModal from '@/components/modal/tradeOrderModal.vue'

export default {
  name: 'ClosedTradeView',
  components: {
    TradeOrderModal,
    ClosedTradeTable,
    FilterCard,
    ClosedTradeModal,
    ColumnsModal,
    BreadcrumbButton,
  },
  mixins: [mixinBase, mixinPermissions],
  data() {
    return {
      columnsNames: getTradeFields(),
      columnsDefaultNames: getDefaultCloseTradeFilter(),
      filter: ClosedTradeSearchRequest(),
      filterLoaded: false,
      columns: [],
      filterFields: null,
    };
  },
  watch: { },
  async created() {
    this.filter['states'] = ['CLOSED'];

    this.filterFields = ClosedTradeFilterFields();
  },
  computed: {
    ...mapGetters('data', ['allOrganizations']),
  },
  methods: {
    ...mapActions('trade', ['getAllTrades']),
    openColumnsModal() {
      this.$refs['columns-modal'].showModal();
    },
    onClosedTradeSelected(item) {
      // console.log("onClosedTradeSelected", selectedId)
      this.$refs['trade-order-modal'].showModal(null, item);
    },
  }
};
</script>

<style>
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #dfe5fb;
  border: none;
  color: #394066;
  text-transform: lowercase;
  font-variant: small-caps;
  height: 10px;
}
</style>
