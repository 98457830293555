<template>
  <b-modal no-fade v-model="dialog.show" size="md" :id="`modal-trade-${uniqueId}`"
           :hide-footer="true" centered @close="closeModal"
           no-close-on-backdrop
  >
    <template #modal-title>
      <span v-if="loading !== true && error == null && trade != null">
       Edit Order #{{ trade.order }}
      </span>
    </template>
    <div style="min-height: 600px">
      <div v-if="loading !== true && error == null">
        <b-row>
          <b-col cols="6" class="mb-1">
            <label>{{ $t('columns.alias') }}</label>
            <b-form-input v-model="trade.openPrice"></b-form-input>
          </b-col>
          <b-col cols="6" class="mb-1">
            <label>{{ $t('columns.closePrice') }}</label>
            <b-form-input v-model="trade.closePrice"></b-form-input>
          </b-col>

          <b-col cols="6" class="mb-1">
            <label>{{ $t('columns.alias') }}</label>
            <b-form-input v-model="trade.profit"></b-form-input>
          </b-col>

          <b-col cols="6" class="mb-1">
            <label>{{ $t('columns.openTime') }}</label>
            <b-form-input v-model="trade.openTime"></b-form-input>
          </b-col>

          <b-col cols="6" class="mb-1">
            <label>{{ $t('columns.closeTime') }}</label>
            <b-form-input v-model="trade.closeTime"></b-form-input>
          </b-col>

          <b-col cols="6" class="mb-1">
            <label>{{ $t('columns.swap') }}</label>
            <b-form-input v-model="trade.swap"></b-form-input>
          </b-col>

          <b-col cols="6" class="mb-1">
            <label>{{ $t('columns.commission') }}</label>
            <b-form-input v-model="trade.commission"></b-form-input>
          </b-col>


        </b-row>
      </div>
    </div>
  </b-modal>
</template>

<script>
import mixinBase from '@/components/mixin/mixinBase';
import mixinNotifications from '@/components/mixin/mixinNotifications';
import { mapActions } from 'vuex';
import Trade from '@/model/trade/Trade';

export default {
  name: 'closedTradeModal',
  components: {},
  mixins: [mixinBase, mixinNotifications],
  data: () => ({
    dialog: {
      show: false,
      tab: 0,

    },
    digitOptions: Array.from({ length: 15 }, (_, index) => ({
      text: (index + 1).toString(),
      value: index + 1
    })),
    uniqueId: 0,
    loading: true,
    error: null,
    tradeId: null,
    account: null,
    trade: {},
  }),

  watch: {
    queryParams: {
      handler() {
        if (this.queryParams.order == null) {
          this.closeModal();
          return;
        }
        if (this.queryParams.order !== this.tradeId) {
          this.showModal(this.queryParams.order);
        }
      },
      deep: true
    }
  },
  created() {
    this.uniqueId = this._uid;
    if (this.queryParams.order != null) {
      this.showModal(this.queryParams.order);
    }
  },
  methods: {
    ...mapActions('trade', ['getTradeById']),
    showModal(tradeId, account) {
      this.tradeId = tradeId;
      this.account = account;
      this.loading = true;
      this.dialog.show = true;
      this.dialog.tab = 0;
      this.refreshData();
      this.pushState({
        tradeId: tradeId,
        account: account,
        tab: 0,
      });
    },
    closeModal() {
      this.dialog.show = false;
      this.trade = {};
      this.onDialogClose();
    },
    onDialogClose() {
      this.tradeId = null;
      this.pushState({
        tradeId: null,
        tab: null,
      });
    },
    refreshData() {
      let $this = this;
      console.log('refreshData modal closedTrades');
      this.getTradeById({
        account: this.account,
        orderId: this.tradeId
      })
          .then(trade => {
            $this.trade = trade;
            $this.error = null;
            $this.loading = false;
          }, err => {
            $this.error = $this.getErrorDescription(err);
            $this.loading = false;
          });
    },
    onSubmitted(server) {
      if (server.id != null) {
        this.selected = server.id;
      }
      this.refreshData();
      this.dialog.tab = 0;
    }
  }
};
</script>

<style scoped>

</style>
